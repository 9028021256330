import multipleAttempts from '../common/lib/multipleAttempts'
import {
  CREATE_MODERATION_PARTICIPANTS,
  ADD_PARTICIPANTS_IN_PROGRESS,
  ENROLLMENT_UPDATED,
  PARTICIPANT_UPDATED,
  MODERATE_TRAY_CLOSED,
  NAVIGATE_TO,
  OPEN_MODERATE_TRAY,
  UPDATE_MODERATION_PAGE,
  UPDATE_MODERATION_PROGRESS_FILTER,
  UPDATE_MODERATION_SEARCH,
  OPEN_ACCOMMODATION_MODAL,
  ACCOMMODATION_MODAL_CLOSED,
  SET_ASSIGNMENT_TIMELIMIT,
  SET_NUM_AUTOGRADING_FAILED,
  SAVE_ACCOMODATIONS
} from '../constants'

export function onTrayClosed () {
  return { type: MODERATE_TRAY_CLOSED }
}

export function openTray (participant) {
  return { type: OPEN_MODERATE_TRAY, participant }
}

export function addParticipantsInProgress (json, response) {
  return {
    type: ADD_PARTICIPANTS_IN_PROGRESS,
    inProgressParticipants: json.map(participant)
  }
}

export function createParticipants (json, response) {
  return {
    type: CREATE_MODERATION_PARTICIPANTS,
    participants: json.map(participant),
    totalPages: totalPages(response.headers)
  }
}

export function setNumAutogradingFailed (json, response) {
  return {
    type: SET_NUM_AUTOGRADING_FAILED,
    numAutogradingFailed: parseInt(response.headers.get('total'), 10)
  }
}

export function navigateToModerateSession ({ assignmentId, quizSessionId }) {
  return {
    type: NAVIGATE_TO,
    path: `/moderation/${assignmentId}/session/${quizSessionId}`
  }
}

export function openAccommodationsModal (participant) {
  return { type: OPEN_ACCOMMODATION_MODAL, participant }
}

export function closeAccommodationsModal () {
  return { type: ACCOMMODATION_MODAL_CLOSED }
}

export function setAssignmentTimeLimit (hasSessionTimeLimit, sessionTimeLimitInSeconds) {
  return { type: SET_ASSIGNMENT_TIMELIMIT, hasSessionTimeLimit, sessionTimeLimitInSeconds }
}

export const saveAccomodations = () => {
  return { type: SAVE_ACCOMODATIONS }
}

export function updateEnrollmentHandler ({ contextId, participantId, userId }) {
  return function (response) {
    return {
      type: ENROLLMENT_UPDATED,
      enrollment: enrollment(response),
      contextId,
      participantId,
      userId
    }
  }
}

export function updateParticipantHandler({ participantId, postActionHandler }) {
  return function (response) {
    return {
      type: PARTICIPANT_UPDATED,
      participant: participant(response),
      participantId,
      postActionHandler
    }
  }
}

export function updatePage (page) {
  return { type: UPDATE_MODERATION_PAGE, page }
}

export function updateProgressFilter (progressFilter) {
  return { type: UPDATE_MODERATION_PROGRESS_FILTER, progressFilter }
}

export function updateSearch (search) {
  return { type: UPDATE_MODERATION_SEARCH, search }
}

function enrollment (raw) {
  return {
    disableTimer: raw.disable_timer,
    extraTimeEnabled: raw.extra_time_enabled,
    extraTimeInSeconds: raw.extra_time_in_seconds,
    increaseTimeAllowance: raw.increase_time_allowance,
    timerMultiplierEnabled: raw.timer_multiplier_enabled,
    timerMultiplierValue: raw.timer_multiplier_value
  }
}

function participant (raw) {
  const { id, user } = raw

  if (!id) {
    throw new Error('Missing participant id')
  }

  return {
    id: String(id),
    avatar: user.avatar_image_url || '',
    name: user.full_name || '?',
    contextId: raw.context_id,
    userId: raw.user_id,
    quizSessionIds: (raw.participant_sessions || []).map(session => String(session.quiz_api_quiz_session_id)),
    extraAttemptsQuiz: raw.extra_attempts,
    maxAttempts: maxAttempts(multipleAttempts(raw.multiple_attempts)),
    disableTimerQuiz: raw.disable_timer,
    extraTimeEnabledQuiz: raw.extra_time_enabled,
    extraTimeInSecondsQuiz: raw.extra_time_in_seconds,
    ...enrollment(raw.enrollment || {})
  }
}

function maxAttempts ({ isAttemptLimited, enabled, maxAttempts }) {
  if (enabled) {
    return isAttemptLimited ? maxAttempts : Infinity
  } else {
    return 1
  }
}

function totalPages (headers) {
  const total = parseInt(headers.get('total'), 10)
  const pageSize = parseInt(headers.get('per-page'), 10)
  const pages = total && pageSize ? Math.ceil(total / pageSize) : 0
  return pages > 1 ? pages : 0 // disable pagination if there is only 1 page
}
