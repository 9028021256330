import debounce from 'debounce'

import { SAVE_ACCOMODATIONS } from '../constants'
import { updateParticipant } from '../actions/apiActions'
import extraTimePerQuizValid from '../util/extraTimePerQuizValid'
import isValidNumberValue from '../util/isValidNumberValue'

const saveAccomodations = debounce((store) => {
  const state = store.getState()

  const { giveExtraTimeSettings, giveExtraAttemptsSettings, moderation } = state
  const { participantId } = giveExtraTimeSettings

  const participantPayload = buildParticipantPayload(
    giveExtraTimeSettings,
    giveExtraAttemptsSettings,
    moderation.participants[participantId]
  )

  if (Object.keys(participantPayload).length === 0) {
    return
  }

  store.dispatch(updateParticipant({
    participantId, participant: participantPayload
  }))
}, 250)

// Trigger API calls in response to redux actions. Use this to propagate
// changes to the backend.
export default (store) => (next) => (action) => {
  if (action.type === SAVE_ACCOMODATIONS) {
    saveAccomodations(store)
  }
  return next(action)
}

function buildParticipantPayload(giveExtraTimeSettings, giveExtraAttemptsSettings, participant) {
  const {
    disableTimerQuiz,
    extraTimeEnabledQuiz,
    extraTimeInSecondsQuiz
  } = giveExtraTimeSettings

  const {
    extraAttemptsQuiz
  } = giveExtraAttemptsSettings

  const {
    disableTimerQuiz: oldDisableTimerQuiz,
    extraAttemptsQuiz: oldExtraAttemptsQuiz,
    extraTimeEnabledQuiz: oldExtraTimeEnabledQuiz,
    extraTimeInSecondsQuiz: oldExtraTimeInSecondsQuiz
  } = participant

  const participantPayload = {}

  if (extraTimePerQuizValid(giveExtraTimeSettings)) {
    if (disableTimerQuiz !== oldDisableTimerQuiz) {
      Object.assign(participantPayload, { disable_timer: disableTimerQuiz })
    }

    if (extraTimeEnabledQuiz !== oldExtraTimeEnabledQuiz) {
      Object.assign(participantPayload, { extra_time_enabled: extraTimeEnabledQuiz })
    }

    if (extraTimeInSecondsQuiz !== oldExtraTimeInSecondsQuiz) {
      Object.assign(participantPayload, { extra_time_in_seconds: extraTimeInSecondsQuiz })

    }
  }

  if (isValidNumberValue(extraAttemptsQuiz) && extraAttemptsQuiz !== oldExtraAttemptsQuiz) {
    Object.assign(participantPayload, { extra_attempts: extraAttemptsQuiz })
  }

  return participantPayload
}